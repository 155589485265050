import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import Banner from '../components/Banner/Banner';

class HomeIndex extends React.Component {
  scrollToRef = ref => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  render() {
    const contactRef = React.createRef();

    return (
      <Layout contactRef={contactRef}>
        <Helmet
          title="JIT Machine Corp"
          meta={[
            {
              name: 'description',
              content: 'Just in Time Machine Corp Website',
            },
            { name: 'keywords', content: 'machining, cnc, welding' },
            {
              name: 'google-site-verification',
              content: 'uEy2gYHXf0UmV2RXlOiSPOcQP5ICMIkuUrjpvqpGMAQ',
            },
          ]}
        />

        <Banner scrollToContact={() => this.scrollToRef(contactRef)} />

        <div id="main">
          <section id="one" className="tiles">
            <article>
              <header className="major">
                <h3>CNC Machine Services</h3>
                <p>See our wide varied of machining services.</p>
              </header>
              <Link to="/cnc-services" className="link primary" />
            </article>
            <article>
              <header className="major">
                <h3>Sample Projects</h3>
                <p>A gallery of our recent projects.</p>
              </header>
              <Link to="/sample-projects" className="link primary" />
            </article>
            <article>
              <header className="major">
                <h3>About</h3>
                <p>The company background</p>
              </header>
              <Link to="/about" className="link primary" />
            </article>
            <article>
              <header className="major">
                <h3>Let's Talk</h3>
                <p>We'd love to give you a quote.</p>
              </header>
              <Link to="/contact" className="link primary" />
            </article>
          </section>
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;
