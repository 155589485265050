import React from 'react';

const Banner = props => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1>Just in Time Machine Corp</h1>
      </header>
      <div className="content">
        <h2>
          WITH OUR HELP, A MEDICAL COMPONENTS COMPANY
          <br /> RECEIVED THEIR PROTOTYPE WITHIN 48 HOURS
        </h2>
      </div>
      {props.scrollToContact && (
        <a className="button" onClick={props.scrollToContact}>
          Submit an RFQ
        </a>
      )}
    </div>
  </section>
);

export default Banner;
